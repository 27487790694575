import {message} from 'antd';
import {BASE_URL, api} from '../../config';

const GET_USER_DETAILS = BASE_URL+"users/";
const LOGIN_USER = BASE_URL+"token/";

//Returns :  id: int    /   title: str
export function get_user_details(id_user) {
    return api.get(GET_USER_DETAILS + id_user).catch(error => {
        message.error(error);
    });
}

export function login_user(username, password) {
    const data = new URLSearchParams();
    data.append('username', username);
    data.append('password', password);

    return api.post(LOGIN_USER, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}



