import {message} from "antd";
import {
    createFeedback,
    getAllFeedbacks,
    getAllUserFeedbacks,
    getFeedbackById
} from "../Services/feedback.service";

export const SET_LIST_FEEDBACKS = "SET_LIST_FEEDBACKS";
export const SET_SINGLE_FEEDBACK = "SET_SINGLE_FEEDBACK";

export function request_create_feedback(rating, commentaire, historique, onCancelModal) {
    return function (dispatch) {
        return createFeedback(rating, commentaire, historique).then(res => {
            if (res !== undefined) {
                message.success("Nous avons bien reçu votre Feedback, merci pour votre collaboration !")
            }
            onCancelModal()
        }).catch(error => {
            console.error("Erreur lors du renommage du chat : ", error);
            message.error("Erreur lors du renommage du chat.");
        });
    };
}


export function request_get_feedback_by_id(idFeedback) {
    return function (dispatch) {
        return getFeedbackById(idFeedback).then(res => {
            if (res !== undefined) {
                //set feedback element
            }
        }).catch(error => {
            console.error("Erreur lors du renommage du chat : ", error);
            message.error("Erreur lors du renommage du chat.");
        });
    };
}

export function request_get_all_feedbacks() {
    return function (dispatch) {
        return getAllFeedbacks().then(res => {
            if (res !== undefined) {
                //set feedbacks list
            }
        }).catch(error => {
            console.error("Erreur lors du renommage du chat : ", error);
            message.error("Erreur lors du renommage du chat.");
        });
    };
}

export function request_get_feedbacks_user() {
    return function (dispatch) {
        return getAllUserFeedbacks().then(res => {
            if (res !== undefined) {
                //set feedbacks list
            }
        }).catch(error => {
            console.error("Erreur lors du renommage du chat : ", error);
            message.error("Erreur lors du renommage du chat.");
        });
    };
}


export const set_single_feedback = (feedback) => {
    return {
        type: SET_SINGLE_FEEDBACK,
        value: feedback,
    };
};
export const set_list_feedback = (list_feedback) => {
    return {
        type: SET_LIST_FEEDBACKS,
        value: list_feedback,
    };
};
