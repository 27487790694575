import React from 'react';
import './header.css';
import { Popover } from 'antd';
import {LogoutOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
function getInitials() {
    const fullName = getNomComplet();
    let initials = "XX";
    // Split the full name into an array of names
    if (fullName) {
        const nameParts = fullName.split(' ');
        // Get the first letter of each name part and capitalize it
        initials = nameParts.map(name => name.charAt(0).toUpperCase()).join('');
    }
    return initials;
}

function getNomComplet() {
    let fullName = sessionStorage.getItem("nom_complet");
    return fullName || "Non identifié";
}

function Profile(props) {

    const navigate = useNavigate();

    const logout = () => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("nom_complet");
        sessionStorage.removeItem("id_user");
        navigate("/");
    };

    const settingsContent = (
        <div className="menu-container-settings" role="menu" aria-label="Settings Menu">
            <div
                className="menu-content-settings"
                onClick={logout}
                role="menuitem"
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        logout();
                    }
                }}
                aria-label="Logout"
            >
                <LogoutOutlined className="pictogramme-menu" aria-hidden="true"/>
                <div>
                    <div className="title-popmenu">Se déconnecter</div>
                </div>
            </div>
        </div>
    );

    return (
        <Popover content={settingsContent} title="Paramètres" placement={"bottom"}>
            <div className="profile-side">
                <div className="profile-card">
                    <div className="profile-avatar" aria-label={`Profile avatar for ${getNomComplet()}`}>
                        {getInitials()}
                    </div>
                    <div className="profile-details">
                        <p className="profile-name">{getNomComplet()}</p>
                        <p className="profile-role">Client</p>
                    </div>
                    <span className="chevron-icon" aria-hidden="true">▼</span>
                </div>
            </div>
        </Popover>



    );
}

export default Profile;