import React from 'react';
import './Title.css';
import { TypeAnimation } from 'react-type-animation';

function Title1(props) {
  return (
    <div className="content">
      <h2>Recherche juridique</h2>
      <TypeAnimation

        sequence={[
          ' Outils de recherche avancée pour trouver des cas, des lois, des régulations, et des précédents juridiques\n' +
          '      pertinents rapidement.',
          500, // wait 1s before replacing "Mice" with "Hamsters"
        ]}
        wrapper="span"
        speed={50}
        repeat={Infinity}
      />
      </div>
  );
}

export default Title1;
