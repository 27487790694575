import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {message} from "antd";

export const BASE_URL ="https://verdictia-be0f34fb30d4.herokuapp.com/api/";
export var user = JSON.parse(sessionStorage.getItem('user'));

export const api = axios.create({
    baseURL: BASE_URL, // Corrected 'baseUrl' to 'baseURL'
});

api.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        // Verify if success is false
        if (response.data && response.data.success === false) {
            message.error(response.data.error);
        }
        return response;
    },
    (error) => {
        // Handle request errors
        if (error.response) {
            if (error.response.data.success===false) {
                Swal.fire({
                    title: 'Error!',
                    text: error.response.data.error,
                    icon: 'error',
                });
            } else {
                message.error('An error occurred');
            }
        } else if (error.request) {
            alert('No response from server');
            message.error('No response from server');
        } else {
            alert('No response from server');
            message.error('Request error: ' + error.message);
        }
        return Promise.reject(error);
    }
);


// "scripts": {
//     "start": "serve -s build",
//         "build": "react-scripts build",
//         "test": "react-scripts test",
//         "eject": "react-scripts eject"
// },