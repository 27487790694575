import React, { useState, useCallback } from 'react';
import './side-menu.css';
import {
    DeleteFilled,
    EditFilled,
    EditOutlined, HistoryOutlined,
    WechatWorkFilled,
} from '@ant-design/icons';
import {Button, Input, Popconfirm, Popover, message, Tooltip} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { create_new_empty_chat, request_get_messages } from "../../../Actions/chat.actions";

function SideMenu(props) {
    const id_active_chat = useSelector((state) => state.chat.chat_id);
    const loading = useSelector((state) => state.chat.loading);
    const dispatch = useDispatch();
    const [idChat, setIdChat] = useState(-1);
    const [titleValue, setTitleValue] = useState('');

    const renameChat = useCallback((title, index) => {
        if (!loading) {
            props.onRename(title, index);
            setTitleValue('');
            setIdChat(-1);
        } else {
            message.warning("Une requête est en cours d'exécution");
        }
    }, [loading, props]);

    const getMessages = useCallback((id) => {
        if (!loading) {
            if (id_active_chat !== id) {
                dispatch(request_get_messages(id));
            } else {
                message.warning("Vous êtes déjà dans ce chat.");
            }
        } else {
            message.warning("Une requête est en cours d'exécution");
        }
    }, [dispatch, loading, id_active_chat]);

    const deleteChat = useCallback((chat_id) => {
        if (!loading) {
            props.onDelete(chat_id);
        } else {
            message.warning("Une requête est en cours d'exécution");
        }
    }, [loading, props]);

    return (
        <div className="side-menu-styling">
            <div className="side-menu-header">
                <div className="header-logo-login" aria-label="Logo and login section" />
                <Tooltip placement={"bottom"} title="Ajouter un nouveau chat">
                    <div className="plus-sign" aria-label="Add new chat" onClick={() => dispatch(create_new_empty_chat())}> + </div>
                </Tooltip>
                {/*<EditOutlined*/}
                {/*    onClick={() => dispatch(create_new_empty_chat())}*/}
                {/*    className="add-chat"*/}
                {/*    aria-label="Add new chat"*/}
                {/*/>*/}
            </div>

            <h4 style={{ padding: '10px', color: 'white', display: 'flex', alignItems:'center' }}>
                <HistoryOutlined style={{ marginRight: '10px',fontSize:"20px" }} />
                <div className="history-title">Historique</div>
            </h4>

            <div className="chat-history">
                {props?.chats?.map((chat) => {
                    return (
                        <Popover
                            key={chat.id}
                            placement="right"
                            style={{ fontSize: '15px' }}
                            content={
                                <div className="d-flex align-items-center">
                                    <div className="m-2">
                                        {idChat === -1 ? chat.title :
                                            <div>
                                                <Input
                                                    value={titleValue}
                                                    onChange={(e) => setTitleValue(e.target.value)}
                                                    aria-label="Edit chat title"
                                                />
                                                <Button
                                                    onClick={() => renameChat(titleValue, chat.id)}
                                                    aria-label="Confirm rename"
                                                >
                                                    Confirmer
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <Popover placement="top" content="Modifier">
                                            <EditFilled
                                                onClick={() => {
                                                    setIdChat(chat.id);
                                                    setTitleValue(chat.title);
                                                }}
                                                style={{
                                                    fontSize: '18px',
                                                    color: 'var(--primary)',
                                                    cursor: 'pointer'
                                                }}
                                                className="mr-2"
                                                aria-label="Edit chat"
                                            />
                                        </Popover>

                                        <Popover placement="top" content="Supprimer">
                                            <Popconfirm
                                                title="Supprimer le chat ?"
                                                okText="Supprimer"
                                                cancelText="Retour"
                                                onConfirm={() => deleteChat(chat.id)}
                                            >
                                                <DeleteFilled
                                                    style={{
                                                        fontSize: '18px',
                                                        color: '#ff7c00',
                                                        cursor: 'pointer'
                                                    }}
                                                    aria-label="Delete chat"
                                                />
                                            </Popconfirm>
                                        </Popover>
                                    </div>
                                </div>
                            }
                            aria-label={`Chat options for ${chat.title}`}
                        >
                            <div
                                className={`chat-element ${chat.id === id_active_chat ? ' active' : ''}`}
                                onClick={() => getMessages(chat.id)}
                                role="button"
                                tabIndex={0}
                                aria-label={`Select chat ${chat.title}`}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        getMessages(chat.id);
                                    }
                                }}
                            >
                                <div className="chat-title">{chat.title}</div>
                                <div className="edit-pen"><EditOutlined /></div>
                            </div>
                        </Popover>
                    );
                })}
            </div>
        </div>
    );
}

export default SideMenu;
