import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import React from "react";
import Login from "./core/scenes/login/login";
import Recherche from "./core/scenes/recherche/recherche";
import PrivateRoute from "./PrivateRoute";
import ErrorPage from "./core/scenes/erreur-page/ErrorPage";
import Stream from "./core/scenes/stream";

function App() {
  return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Login/>}/>
            <Route path="/recherche-juridique" element={<PrivateRoute element={Recherche} />} />
            <Route path="*" element={<ErrorPage />} /> {/* Catch-all route */}
        </Routes>
      </Router>
  );
}

export default App;