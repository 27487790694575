import React from 'react';
import './login.css';
import {Carousel } from 'antd';
import Title1 from "./carousel/Title1";
import Title2 from './carousel/Title2';
import Title4 from './carousel/Title4';
import Title3 from './carousel/Title3';
import LoginForm from "./login-form";

function Login(props) {


    return (
        <div className="container-fluid p-0 m-0 login-body">
            <div className="row p-0 m-0">
                <div className="col-lg-3 left-side">
                    <div className="mt-3">
                        <div className="header-logo-login"/>
                    </div>
                    <div className="carousel-container-login">
                        <Carousel effect={"fade"} className="carousel-login-element" dotPosition={"bottom"}
                                  autoplay={true} autoplaySpeed={8000}>
                            <Title1/>
                            <Title2/>
                            <Title3/>
                            <Title4/>
                        </Carousel>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="form-container">
                            <LoginForm/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
