import React from 'react';
import ReactDOM from 'react-dom/client';
import {ConfigProvider } from 'antd';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from "./core/Store/store";
// import 'notyf/notyf.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#2E4B77',
                    },
                }}
            >
                <App />
            </ConfigProvider>
        </Provider>
    </React.StrictMode>
);