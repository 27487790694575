import React from 'react';
import './proposition.css';
import { ProfileOutlined, FolderOpenOutlined, ExperimentOutlined, LaptopOutlined } from '@ant-design/icons';


function Proposition({ setInstruction }) {
    const propositions = [
        {
            icon: <ProfileOutlined className="icon-proposition" aria-hidden="true" />,
            description: "Quels sont les types de contrats de travail au Québec ?"
        },
        {
            icon: <ExperimentOutlined className="icon-proposition" aria-hidden="true" />,
            description: "Qui peut exercer le métier d'éducatrice à la petite enfance en garderie ?"
        },
        {
            icon: <FolderOpenOutlined className="icon-proposition" aria-hidden="true" />,
            description: "Comment décrire un accident de travail ?"
        },
        {
            icon: <LaptopOutlined className="icon-proposition" aria-hidden="true" />,
            description: "De quoi parle la loi 25 ?"
        }
    ];
    return (
        <div className="proposition-body" role="list" aria-label="Propositions de chat">
            {propositions.map((proposition, index) => (
                <div
                    className="proposition-schema"
                    key={index}
                    onClick={() => setInstruction(proposition.description)}
                    role="listitem"
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            setInstruction(proposition.description)
                        }
                    }}
                    aria-label={proposition.description}
                >
                    {proposition.icon}
                    <span>{proposition.description}</span>
                </div>
            ))}
        </div>
    );
}
export default Proposition;
