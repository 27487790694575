// reducer.js

import {SET_AUTH, SET_LOADING} from "../Actions/user.action";

const initialState = {
    isAuthenticated: false,
    loading: true,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH:
            return {
                ...state,
                isAuthenticated: action.payload,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return state;
    }
};
