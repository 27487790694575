import {
    ADD_CHAT,
    DELETE_CHAT,
    RENAME_CHAT,
    SET_CHATS,
    SET_LOADING_CONTENT,
    UPDATE_BY_ID,
    SET_LOADING_VALUE,
    SET_MESSAGES,
    SET_CHAT_ID,
    ADD_MESSAGE, STREAM_MESSAGE,
    CREATE_NEW_EMPTY_CHAT,
    SET_INSTRUCTION, SET_LAW_HTML, ADD_UI_MESSAGE
} from '../Actions/chat.actions';

const initialState = {
    chats: [],
    chat_id: -1,
    history_messages: [],
    //     {
    //         "id": 587,
    //         "role": "assistant",
    //         "content": "Il semble que vous testiez la fonctionnalité. Comment puis-je vous aider ? Si vous avez des questions sur le droit du Québec ou du Canada, n'hésitez pas à les poser.",
    //         "nb_tokens": 48,
    //         "timestamp": "2024-08-01T19:06:11.075150Z"
    //     },
    //     {
    //         "id": 586,
    //         "role": "user",
    //         "content": "test",
    //         "nb_tokens": 1,
    //         "timestamp": "2024-08-01T19:06:11.075150Z"
    //     },
    //     {
    //         "id": 585,
    //         "role": "assistant",
    //         "content": "D'accord ! Si vous avez d'autres questions ou besoin d'assistance, n'hésitez pas à demander. Je suis ici pour vous aider.",
    //         "nb_tokens": 40,
    //         "timestamp": "2024-08-01T19:05:23.982796Z"
    //     },
    //     {
    //         "id": 584,
    //         "role": "user",
    //         "content": "non c'est bon",
    //         "nb_tokens": 4,
    //         "timestamp": "2024-08-01T19:05:23.981799Z"
    //     },
    //     {
    //         "id": 583,
    //         "role": "assistant",
    //         "content": "Il semble que vous ayez testé la fonctionnalité. Comment puis-je vous aider ? Si vous avez des questions sur le droit fédéral canadien, n'hésitez pas à les poser.",
    //         "nb_tokens": 56,
    //         "timestamp": "2024-08-01T19:04:52.709796Z"
    //     },
    //     {
    //         "id": 582,
    //         "role": "user",
    //         "content": "test",
    //         "nb_tokens": 1,
    //         "timestamp": "2024-08-01T19:04:52.709796Z"
    //     },
    //     {
    //         "id": 581,
    //         "role": "assistant",
    //         "content": "La Loi 25, également connue sous le nom de Loi sur la protection des renseignements personnels, impose des obligations strictes pour protéger les données personnelles. Veuillez consulter les documents législatifs pour des informations détaillées et à jour.",
    //         "nb_tokens": 401,
    //         "timestamp": "2024-08-01T19:01:02.227699Z"
    //     },
    //     {
    //         "id": 580,
    //         "role": "user",
    //         "content": "De quoi parle la loi 25 ?",
    //         "nb_tokens": 9,
    //         "timestamp": "2024-08-01T19:01:02.227699Z"
    //     },
    //     {
    //         "id": -2,
    //         "chat_id": 153,
    //         "role": "user",
    //         "content": "coucou 2",
    //         "temporary": true
    //     },
    //     {
    //         "id": -1,
    //         "chat_id": 153,
    //         "role": "assistant",
    //         "content": "",
    //         "temporary": true
    //     },
    //     {
    //         "id": 588,
    //         "chat_id": 153,
    //         "role": "user",
    //         "content": "coucou 1",
    //         "temporary": true
    //     },
    //     {
    //         "id": 589,
    //         "chat_id": 153,
    //         "role": "assistant",
    //         "content": "Bonjour ! Comment puis-je vous aider aujourd'hui ? Si vous avez des questions sur le droit du Québec ou du Canada fédéral, n'hésitez pas à les poser.",
    //         "temporary": true
    //     }
    // ],
    loading: false,
    instruction: "", // Added to match `SET_INSTRUCTION`
    content: "",
    loading_content: true
};

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_UI_MESSAGE:
            const newHistoryMessages = [...state.history_messages,action.value];
            return {
                ...state,
                history_messages: newHistoryMessages,
            };

        case CREATE_NEW_EMPTY_CHAT:
            return {
                ...state,
                chat_id: -1,
                history_messages: [],
            };

        case SET_LAW_HTML:
            return {
                ...state,
                content: action.content,
            };
        case SET_LOADING_CONTENT:
            return {
                ...state,
                loading_content: action.value,
            };

        case SET_INSTRUCTION:
            return {
                ...state,
                instruction: action.instruction,
            };

        case SET_CHAT_ID:
            console.log(action.chat_id)
            return {
                ...state,
                chat_id: action.chat_id,
            };

        case SET_LOADING_VALUE:
            return {
                ...state,
                loading: action.value,
            };

        case SET_CHATS:
            return {
                ...state,
                chats: action.value,
            };

        case RENAME_CHAT:
            return {
                ...state,
                chats: state.chats.map(chat =>
                    chat.id === action.chat_id ? {...chat, title: action.title} : chat
                ),
            };

        case DELETE_CHAT:
            return {
                ...state,
                chats: state.chats.filter(chat => chat.id !== action.value),
            };

        case ADD_CHAT:
            return {
                ...state,
                chats: [action.value, ...state.chats], // Add new chat at the beginning
            };

        case SET_MESSAGES:
            return {
                ...state,
                // history_messages: action.value.sort((a, b) => b.id - a.id), // Ensure messages are sorted
                history_messages: action.value, // Ensure messages are sorted
            };

        case ADD_MESSAGE:
            const updatedHistoryMessages = [action.value, ...state.history_messages]; // Ensure messages are sorted
            return {
                ...state,
                history_messages: updatedHistoryMessages,
            };

        //attention il y a ici la gestion de l'id -1 que je crée initialement c pour le changer quand jai la réponse
        case UPDATE_BY_ID:
            return {
                ...state,
                history_messages: state.history_messages.map((msg) => {
                    // Si on met à jour un message temporaire
                    if ((msg.id === -1 && action.id === -1) || (msg.id === -2 && action.id === -2)) {
                        return { ...msg, ...action.message }; // Mise à jour du contenu
                    }

                    // Si on met à jour un message réel (après avoir reçu l'ID réel)
                    if (msg.id === action.id) {
                        return { ...msg, ...action.message }; // Mise à jour du contenu
                    }

                    // Sinon, on retourne le message tel quel
                    return msg;
                })
            };
        case STREAM_MESSAGE:
            return {
                ...state,
                history_messages: state.history_messages.map(msg =>
                    (msg.id === -1)
                        ? { ...msg, content: action.value }
                        : msg
                )
            };
        default:
            return state;
    }
};

export default chatReducer;
