import React, {useEffect, useState} from 'react';
import './header-mobile.css';
import {
    CopyFilled,
    DribbbleCircleFilled, EditOutlined,
    FolderAddOutlined,
    LayoutFilled,
    MenuOutlined,
    ProfileFilled,
    ScheduleFilled,
    ThunderboltFilled
} from "@ant-design/icons";
import {Drawer, Input, Popconfirm, Popover, Tooltip} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {
    create_new_empty_chat,
    request_get_messages
} from "../../../Actions/chat.actions";
import Profile from "./Profile";

function HeaderMobile(props) {
    const [open, setOpen] = useState(false);
    const sessions = useSelector((state) => state.chat.chats);
    const chat_id = useSelector((state) => state.chat.chat_id);
    const dispatch = useDispatch();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const content = (
        <div className="menu-container-mobile" role="menu" aria-label="Mobile Menu">
            <div className="menu-content-menu" role="menuitem" tabIndex={0} aria-label="Recherche juridique">
                <ProfileFilled className="pictogramme-menu" aria-hidden="true"/>
                <div>
                    <div className="title-popmenu">Recherche juridique</div>
                    <div className="description-p-mobile">Pour une recherche juridique rapide.</div>
                </div>
            </div>
            <div className="menu-content-menu inactif-tag" role="menuitem" tabIndex={0} aria-label="Revue de contrat">
                <DribbbleCircleFilled className="pictogramme-menu" aria-hidden="true"/>
                <div>
                    <div className="title-popmenu">Revue de contrat</div>
                    <div className="description-p">Pour analyser et réviser des contrats.</div>
                </div>
            </div>
            <div className="menu-content-menu inactif-tag" role="menuitem" tabIndex={0} aria-label="Jurisprudence">
                <ScheduleFilled className="pictogramme-menu" aria-hidden="true"/>
                <div>
                    <div className="title-popmenu">Jurisprudence</div>
                    <div className="description-p">Pour trouver des décisions judiciaires pertinentes.</div>
                </div>
            </div>
            <div className="menu-content-menu inactif-tag" role="menuitem" tabIndex={0}
                 aria-label="Génération de documents">
                <CopyFilled className="pictogramme-menu" aria-hidden="true"/>
                <div style={{textAlign: "left"}}>
                    <div className="title-popmenu">Génération de documents</div>
                    <div className="description-p">Pour générer automatiquement des documents juridiques standardisés.
                    </div>
                </div>
            </div>
            <div className="menu-content-menu inactif-tag" role="menuitem" tabIndex={0}
                 aria-label="Gestion de la conformité">
                <LayoutFilled className="pictogramme-menu" aria-hidden="true"/>
                <div>
                    <div className="title-popmenu">Gestion de la conformité</div>
                    <div className="description-p">Pour gérer les obligations réglementaires et de conformité.</div>
                </div>
            </div>
            <div className="menu-content-menu inactif-tag" role="menuitem" tabIndex={0}
                 aria-label="Gestion des risques">
                <ThunderboltFilled className="pictogramme-menu" aria-hidden="true"/>
                <div>
                    <div className="title-popmenu">Gestion des risques</div>
                    <div className="description-p">Pour la gestion des risques juridiques.</div>
                </div>
            </div>
        </div>
    );

    return (
        <div className='header-mobile'>
            <div
                className="burger"
                role="button"
                aria-label="Open menu"
                tabIndex={0}
                onClick={showDrawer}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        showDrawer();
                    }
                }}
            >
                <MenuOutlined
                    // onClick={() => dispatch(create_new_empty_chat())}
                    style={{fontSize: "25px", color: '#5b5b5b'}} aria-hidden="true"/>
            </div>
            <div className="model-type">

                <Popover
                    placement="bottom"
                    title={"Types de services"}
                    content={content}
                    aria-label="Service types"
                >
                    <div style={{fontSize: "20px"}} role="button" tabIndex={0} aria-label="View service types">
                        VerdictIA
                    </div>
                </Popover>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="icon-md text-token-text-tertiary"
                    aria-hidden="true"
                >
                    <path fill="currentColor" fillRule="evenodd"
                          d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                          clipRule="evenodd"/>
                </svg>
            </div>
            <div className="create-new-button">
                <Tooltip placement={"left"} title="Ajouter un nouveau chat">
                    <div style={{color:"var(--primary)"}} className="plus-sign" aria-label="Add new chat" onClick={() => dispatch(create_new_empty_chat())}> + </div>
                </Tooltip>
                {/*<EditOutlined*/}
                {/*    onClick={() => dispatch(create_new_empty_chat())}*/}
                {/*    style={{fontSize: "25px", color: '#5b5b5b'}}*/}
                {/*    aria-hidden="true"*/}
                {/*/>*/}
            </div>

            <Drawer
                title="Sessions"
                placement={"left"}
                onClose={onClose}
                size={"default"}
                footer={<Profile/>}
                open={open}
                aria-labelledby="sessions-drawer"
            >
                {
                    sessions.map((session) => {
                        return (
                            <p
                                onClick={() => dispatch(request_get_messages(session?.id))}
                                key={session.id}
                                className={`chat-element-mobile ${session.id === chat_id ? ' active' : ''}`}
                                style={{marginBottom: '23px', fontSize: '18px'}}
                                role="listitem"
                                aria-label={`Chat session titled ${session.title}`}
                            >
                                {session.title}
                            </p>
                        )
                    })
                }
            </Drawer>
        </div>
    );
}

export default HeaderMobile;
