import chatReducer from "../Reducers/charReducer";
import {authReducer} from "../Reducers/authReducer";
import { configureStore  } from '@reduxjs/toolkit';

const store = configureStore({
    reducer: {
        chat: chatReducer,
        auth: authReducer,
    },
});

export default store;