import React from 'react';

function Title2(props) {
  return (
    <div className="content">
      <h2>Revue de contrat</h2>
      Une fonctionnalité permettant d'analyser et de réviser des contrats automatiquement, de détecter des clauses
      problématiques, et de suggérer des modifications.</div>
  );
}

export default Title2;