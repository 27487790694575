import React, {useEffect, useState} from 'react';
import {BorderBottomOutlined, LockOutlined, RadiusUpleftOutlined, UserOutlined} from "@ant-design/icons";
import {Button, Input, message} from "antd";
import './login.css'
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {RequestLogin, setAuth, setLoading} from "../../Actions/user.action";
import axios from "axios";
import {BASE_URL} from "../../../config";
import {TypeAnimation} from "react-type-animation";
import Divider from "antd/es/divider";
import { notification } from 'antd';


function LoginForm(props) {

    const openNotification=()=>{
        notification.open({
            message: `Bientôt disponible`,
            description: `Cette fonctaionnalité n'est pas endcore disponible pour le moment.`,
            placement:"topRight"
        });
    };

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        const checkAuth = async () => {
            const token = sessionStorage.getItem("token");
            if (token) {
                dispatch(setLoading(true));
                try {
                    const response = await axios.get(`${BASE_URL}check-auth`, {
                        headers: {Authorization: `Bearer ${token}`}
                    });
                    if (response.data.success && response.data.data.is_authenticated) {
                        redirectToPageX("/recherche-juridique")
                    } else {
                        dispatch(setAuth(false));
                        navigate("/");
                    }
                } catch (error) {
                    dispatch(setAuth(false));
                    navigate("/");
                } finally {
                    dispatch(setLoading(false));
                }
            } else {
                navigate("/");
            }
        };
        checkAuth();
    }, [dispatch, navigate]);


    const handleLogin = () => {
        if (!username || !password) {
            message.error('Les champs Nom d\'utilisateur et Mot de passe sont obligatoires');
            return;
        }

        dispatch(RequestLogin(username, password, redirectToPageX));
    };

    const redirectToPageX = (path) => {
        navigate(path); // Remplace '/x' par le chemin vers la page souhaitée
    };

    return (
        <div className="form-content">
            <h2><b>Bienvenu</b></h2>
            <TypeAnimation

                sequence={[
                    'On IA-dapte la loi à votre service.',
                    500, // wait 1s before replacing "Mice" with "Hamsters"
                ]}
                wrapper="span"
                speed={50}
                repeat={Infinity}
            />
            {/*<h4>On <strong> IA</strong>-dapte la loi à votre service.</h4>*/}
            <br/>
            <br/>
            <br/>
            <div className="input-structure">
                {/*<div className="icon-title">*/}
                {/*    <UserOutlined/>*/}
                {/*    <div>Nom d'utilisateur</div>*/}
                {/*</div>*/}
                <Input
                    size="large"
                    placeholder="Entrez votre nom d'utilisateur"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleLogin();
                        }
                    }}
                />
            </div>
            <br/>
            <div className="input-structure">
                {/*<div className="icon-title">*/}
                {/*    <LockOutlined/>*/}
                {/*    <div>Mot de passe</div>*/}
                {/*</div>*/}
                <Input.Password
                    size="large"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleLogin();
                        }
                    }}
                    placeholder="Entrez votre mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <br/>
            <Button size="large" type="primary" style={{width: "100%"}} onClick={handleLogin} >Se connecter</Button>


            <br/>
            <Divider style={{color:"var(--primary)"}}>Ou</Divider>
            <div className="sso-container">
                <a href="#" className="sso-button" onClick={openNotification}>
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1200px-Google_%22G%22_logo.svg.png"
                        alt="Google Logo"/>
                        Connexion avec Google
                </a>
                <a href="#" className="sso-button" onClick={openNotification}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
                         alt="Microsoft Logo"/>
                        Connexion avec Microsoft
                </a>
                <a href="#" className="sso-button" onClick={openNotification}>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
                         alt="Apple Logo"/>
                        Connexion avec Apple
                </a>
            </div>
        </div>
    );
}

export default LoginForm;