import React from 'react';
import './header.css';
import {
    ProfileFilled,
    CaretDownOutlined,
    CheckCircleFilled,
    ScheduleFilled, DribbbleCircleFilled, CopyFilled, LayoutFilled, ThunderboltFilled, LogoutOutlined,
} from '@ant-design/icons';
import { Popover } from 'antd';
import Profile from "./Profile";


function Header(props) {
    const content = (
        <div className="menu-container" role="menu" aria-label="Main Menu">
            <div className="menu-content-menu">
                <ProfileFilled className="pictogramme-menu" aria-hidden="true"/>
                <div>
                    <div className="title-popmenu">Recherche juridique</div>
                    <div className="description-p">
                        Outils de recherche avancée pour trouver des cas, des lois, des régulations, et des précédents juridiques pertinents rapidement.
                    </div>
                </div>
                <CheckCircleFilled style={{ marginLeft: "auto", fontSize: "15px" }} aria-hidden="true"/>
            </div>

            {/* Repeat for other menu items */}
            <div className="menu-content-menu inactif-tag">
                <DribbbleCircleFilled className="pictogramme-menu" aria-hidden="true"/>
                <div>
                    <div className="title-popmenu">Revue de contrat</div>
                    <div className="description-p">
                        Une fonctionnalité permettant d'analyser et de réviser des contrats automatiquement, de détecter des clauses problématiques, et de suggérer des modifications.
                    </div>
                </div>
            </div>

            <div className="menu-content-menu inactif-tag">
                <ScheduleFilled className="pictogramme-menu" aria-hidden="true"/>
                <div>
                    <div className="title-popmenu">Jurisprudence</div>
                    <div className="description-p">
                        Outils de recherche avancée pour trouver des décisions judiciaires pertinentes en fonction de mots-clés, de dates, de juridictions, de types de cas, et d'autres critères spécifiques.
                    </div>
                </div>
            </div>

            <div className="menu-content-menu inactif-tag">
                <CopyFilled className="pictogramme-menu" aria-hidden="true"/>
                <div style={{ textAlign: "left" }}>
                    <div className="title-popmenu">Génération de documents</div>
                    <div className="description-p">
                        Création automatique de documents juridiques standardisés, tels que des contrats, des accords, des lettres de mise en demeure, etc.
                    </div>
                </div>
            </div>

            <div className="menu-content-menu inactif-tag">
                <LayoutFilled className="pictogramme-menu" aria-hidden="true"/>
                <div>
                    <div className="title-popmenu">Gestion de la conformité</div>
                    <div className="description-p">
                        Surveillance et gestion des obligations réglementaires et de conformité pour les entreprises, avec des alertes automatiques en cas de changements législatifs pertinents.
                    </div>
                </div>
            </div>

            <div className="menu-content-menu inactif-tag">
                <ThunderboltFilled className="pictogramme-menu" aria-hidden="true"/>
                <div>
                    <div className="title-popmenu">Gestion des risques</div>
                    <div className="description-p">
                        Évaluation et gestion des risques juridiques pour les entreprises, avec des recommandations sur la manière de les atténuer.
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="header-container">
            <Popover content={content} title="Type de service" placement={"right"}>
                <div
                    style={{ marginLeft: '15px', marginTop: '10px' }}
                    role="button"
                    aria-label="Open model menu"
                    tabIndex={0}
                >
                    <h5 className="title_menu_modele" style={{ display: 'inline' }}>Recherche Juridique</h5>
                    <CaretDownOutlined style={{ marginLeft: '10px' }} aria-hidden="true"/>
                </div>
            </Popover>

            <Profile/>

        </div>
    );
}

export default Header;
