import React from 'react';

function Title4(props) {
  return (
    <div className="content">
      <h2>Génération de documents</h2>
      Création automatique de documents juridiques standardisés, tels que des contrats, des accords, des lettres de mise
      en demeure, etc.</div>
  );
}

export default Title4;