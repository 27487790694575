import React, { useEffect, useRef } from 'react';
import './LawDrawer.css';
import Drawer from 'antd/es/drawer';
import { useDispatch, useSelector } from 'react-redux';
import { request_get_law_html } from '../../Actions/chat.actions';
import './canlii.css'

function LawDrawer(props) {
    const chat = useSelector((state) => state.chat);
    const dispatch = useDispatch();
    const iframeRef = useRef(null);

    useEffect(() => {
        if (props.visible && props.title) {
            // dispatch(request_get_law_html(props.title));
        }
    }, [dispatch, props.visible, props.title]);

    return (
        <Drawer
            className="law-drawer"
            onCancel={props.onClose}
            onLoading={chat.loading_content}
            title={props.title?.replace('[', '').replace(']', '')}
            placement="right"
            closable={true}
            onClose={props.onClose}
            open={props.visible}
            key="right"
            size="large"
        >
            {chat.loading_content ? (
                <div className="coming-soon-container">
                    <div className="coming-soon">
                        <span className="letter">C</span>
                        <span className="letter">h</span>
                        <span className="letter">a</span>
                        <span className="letter">r</span>
                        <span className="letter">g</span>
                        <span className="letter">e</span>
                        <span className="letter">m</span>
                        <span className="letter">e</span>
                        <span className="letter">n</span>
                        <span className="letter">t</span>
                        <span className="space"></span>
                        <span className="letter">.</span>
                        <span className="letter">.</span>
                        <span className="letter">.</span>
                    </div>
                </div>
            ) : (
                <div className="html-container">
                    <div
                        className="assistant-message"
                        dangerouslySetInnerHTML={{ __html: chat.content }}
                    />
                </div>
            )}
        </Drawer>
    );
}

export default LawDrawer;
