import React, { useState, useEffect, useRef } from 'react';
import showdown from 'showdown';
import './AssistantMessage.css';
import Feedback from './feedback';
import { useSelector } from 'react-redux';
import LawDrawer from './LawDrawer';

const AssistantMessage = ({ content }) => {
    const loading = useSelector((state) => state.chat.loading);
    const [visible, setVisible] = useState(false);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [selectedContent, setSelectedContent] = useState('');
    const messageRef = useRef(null);

    const onCloseModal = () => {
        setVisible(false);
    };

    const handleFeedbackClick = () => {
        if (!loading) {
            setVisible(true);
        }
    };

    const converter = new showdown.Converter();
    let htmlContent = converter.makeHtml(content);

    // Function to add target="_blank" and rel="noopener noreferrer" to all links
    htmlContent = htmlContent?.replace(/<a\s+href=/g, '<a target="_blank" rel="noopener noreferrer" href=');

    // Regex to find text within square brackets and wrap it with a <span> tag
    htmlContent = htmlContent?.replace(/(\[([^\]]+)\]|\【([^\】]+)\】)/g, (match, p1) => {
        const spanId = `span-${Math.random().toString(36).substr(2, 9)}`;
        return `<span id="${spanId}" class="blue-text"> ${p1} </span>`;
    });

    const handleSpanClick = (content) => {
        setVisibleDrawer(true);
        setSelectedContent(content);
    };

    useEffect(() => {
        if (messageRef.current) {
            const spans = messageRef.current.querySelectorAll('.blue-text');
            spans.forEach(span => {
                // span.onclick = () => handleSpanClick(span.innerText);
            });
        }
    }, [htmlContent]);

    return (
        <div className="assistant-message-container">
            <div
                ref={messageRef}
                className="assistant-message"
                dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
            <div className="hover-text" onClick={handleFeedbackClick}>
                <span className="icon">❖</span>
                <span>Évaluer ce message</span>
            </div>
            <Feedback onClose={onCloseModal} visible={visible} />
            <LawDrawer title={selectedContent} onClose={() => setVisibleDrawer(false)} visible={visibleDrawer} />
        </div>
    );
};

export default AssistantMessage;
