import React, { useEffect, useRef } from 'react';
import './messages.css';
import logo from './Calque 1.png';
import AssistantMessage from "./AssistantMessage";

function Messages(props) {
    const messagesEndRef = useRef(null);

    // Scroll to the bottom of the chat when new messages arrive
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [props.history_messages, props.assistantMessage]); // Include assistantMessage in dependency array

    return (
        <div className="messages-body">
            <div ref={messagesEndRef} />
            {
                [...props.history_messages].sort((b, a) => {
                // Si les deux messages ont des IDs positifs, tri par ID croissant (les plus anciens en haut)
                if (a.id > 0 && b.id > 0) {
                    return a.id - b.id;
                }

                // Si un seul des messages a un ID positif, il doit rester en haut
                if (a.id > 0 && b.id < 0) {
                    return -1; // a avant b
                }
                if (a.id < 0 && b.id > 0) {
                    return 1; // b avant a
                }

                // Si les deux messages ont des IDs négatifs, tri par ID croissant (-2 avant -1)
                return a.id - b.id;
            }).map((message, index) => (
                <div key={index} className="message-container">
                    {message.role === 'user' ? (
                        <div className="user-message-disposition">
                            <div className="user-message">
                                {message.content}
                            </div>
                        </div>
                    ) : (
                        message.content!=="" &&<div className="d-flex chat-disposition" style={{ width: '100%' }}>
                            <img style={{ height: '50px' }} src={logo} alt="Assistant Logo" />
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <AssistantMessage content={message.content} />
                            </div>
                        </div>
                    )}
                </div>
            ))

            }
        </div>
    );

}

export default Messages;
