import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    request_delete_chat,
    add_ui_message,
    request_get_chats,
    request_rename_chat, request_stream_response, set_instruction,
} from '../../Actions/chat.actions';
import {Button, Input} from 'antd';
import {SendOutlined} from '@ant-design/icons';
import Messages from './messages';
import Proposition from './proposition';
import Loader from './loader';
import SideMenu from '../common/side-menu/side-menu';
import HeaderMobile from '../common/header/header-mobile';
import Header from '../common/header/header';
import {create_chat} from '../../Services/chat.service';
import {useMediaQuery} from 'react-responsive';
import './chat.css';
import './recherche.css';
import './recherche-mobile.css';

function Recherche() {
    const chats = useSelector((state) => state.chat.chats);
    const chat_id = useSelector((state) => state.chat.chat_id);
    const history_messages = useSelector((state) => state.chat.history_messages);
    const [assistantMessage, setAssistantMessage] = useState("");
    const [instruction, setInstruction] = useState("");
    const loading = useSelector((state) => state.chat.loading);
    const scrollRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        const chatContent = scrollRef.current;
        if (chatContent) {
            chatContent.scrollTop = chatContent.scrollHeight;
        }
    }, [history_messages]);

    useEffect(() => {
        dispatch(request_get_chats());
    }, [dispatch]);

    const onClickChat = (title) => {
        dispatch(create_chat(title));
    };

    const onRename = (title, idChat) => {
        dispatch(request_rename_chat(title, idChat));
    };

    const onDelete = (id) => {
        dispatch(request_delete_chat(id));
    };

    const chat_with_assistant = () => {
        dispatch(add_ui_message(instruction, 'user', chat_id));
        dispatch(add_ui_message("", 'assistant', chat_id));
        dispatch(request_stream_response(instruction, chat_id));
        setInstruction('')
    };

    const isMobile = useMediaQuery({query: '(max-width: 995px)'});

    return (
        <div className="container-fluid p-0 m-0">
            {!isMobile ? (
                <div className="web-view">
                    <div className="row p-0 m-0">
                        <aside className="col-sm-4 col-md-3 col-lg-2 left-side-menu m-0 p-0 side-menu-styling h-100"
                               aria-label="Side Menu">
                            <SideMenu
                                onClickChat={onClickChat}
                                onRename={onRename}
                                onDelete={onDelete}
                                chats={chats}
                            />
                        </aside>
                        <main className="col-sm-8 col-md-9 col-lg-10 m-0 p-0 w-100 d-flex justify-content-center"
                              role="main">
                            <div className="container-fluid p-0 m-0">
                                <Header/>
                                <div className="chat-body container-fluid">
                                    <div className="chat-upper-area" ref={scrollRef}>
                                        {/*<div className="chat-content">*/}
                                        {history_messages?.length !== 0 ?
                                            <Messages assistantMessage={assistantMessage}
                                                      history_messages={history_messages}/> :
                                            <Proposition setInstruction={setInstruction}/>}
                                        {/*</div>*/}
                                        {/*{loading && <Loader/>}*/}
                                    </div>
                                    <div className="">
                                        <div className="chat-down-area">
                                            <Input.TextArea
                                                autoSize={{minRows: 1, maxRows: 4}}
                                                size="large"
                                                className="chat-input"
                                                placeholder="Posez moi une question..."
                                                value={instruction}
                                                onChange={(e) => setInstruction(e.target.value)}
                                            />
                                            <Button
                                                loading={loading}
                                                type="primary"
                                                className="send-button"
                                                icon={<SendOutlined/>}
                                                onClick={() => instruction.trim() ? chat_with_assistant() : alert('erreur')}
                                            >
                                                Envoyer
                                            </Button>

                                        </div>
                                        <div className="disclaimer-error">
                                            Les résultats fournis par Verdictia sont indicatifs. Pour une confirmation, veuillez consulter les textes de lois.
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </main>
                    </div>
                </div>

            ) : (
                <div className="mobile-view">
                    <HeaderMobile/>
                    <section className="chat-body-mobile" aria-live="polite" role="region">
                        {history_messages?.length !== 0 ?
                            <Messages assistantMessage={assistantMessage} history_messages={history_messages}/>
                            : <Proposition setInstruction={setInstruction}/>}
                    </section>
                    <div className="commands-mobile">
                        <div className="d-flex" >
                            <Input.TextArea
                                autoSize={{minRows: 1, maxRows: 4}}
                                size="large"
                                className="chat-input"
                                placeholder="Posez moi une question..."
                                value={instruction}
                                onChange={(e) => setInstruction(e.target.value)}
                                aria-label="Type your message"
                            />
                            <Button
                                loading={loading}
                                type="primary"
                                className="send-button"
                                icon={<SendOutlined/>}
                                aria-label="Send message"
                                onClick={() => instruction.trim() ? chat_with_assistant() : alert('erreur')}
                            >
                                Envoyer
                            </Button>
                        </div>
                        <div className="disclaimer-error">
                            Les résultats fournis par Verdictia sont indicatifs. Pour une confirmation, veuillez consulter les textes de lois.
                        </div>
                    </div>

                </div>
            )}
        </div>
    );
}

export default Recherche;
