import React from 'react';

function Title3(props) {
  return (
    <div className="content">
      <h2>Jurisprudence</h2>
      Outils de recherche avancée pour trouver des décisions judiciaires pertinentes en fonction de mots-clés, de dates,
      de juridictions, de types de cas, et d'autres critères spécifique</div>
  );
}

export default Title3;