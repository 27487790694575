import { api, BASE_URL } from "../../config";
import { message } from "antd";

// Define endpoint URLs
const FEEDBACK_ENDPOINT = `${BASE_URL}feedbacks/`;
const USER_FEEDBACK_ENDPOINT = `${BASE_URL}users/{user_id}/feedbacks/`;

// Create feedback function
export function createFeedback(rating, commentaire, historique) {
    const data = {
        historique,
        commentaire,
        rating,
    };

    return api.post(FEEDBACK_ENDPOINT, data).catch(error => {
        message.error(`Failed to create feedback: ${error.message}`);
    });
}

// Get feedback by ID function
export function getFeedbackById(id) {
    return api.get(`${FEEDBACK_ENDPOINT}${id}/`).catch(error => {
        message.error(`Failed to get feedback by ID: ${error.message}`);
    });
}

// Get all feedbacks function
export function getAllFeedbacks() {
    return api.get(FEEDBACK_ENDPOINT).catch(error => {
        message.error(`Failed to get all feedbacks: ${error.message}`);
    });
}

// Get all feedbacks for a specific user function
export function getAllUserFeedbacks(userId) {
    const url = USER_FEEDBACK_ENDPOINT.replace("{user_id}", userId);
    return api.get(url).catch(error => {
        message.error(`Failed to get all user feedbacks: ${error.message}`);
    });
}