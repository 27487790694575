import {message} from 'antd';
import {BASE_URL, api} from '../../config';

const CREATE_CHAT_PATH = BASE_URL + '/chats/';
const RENOMMER_CHAT_PATH = BASE_URL + 'chats/';
const DELETE_CHAT_PATH = BASE_URL + 'chats/';
const GET_ALL_CHATS = BASE_URL + 'chats';
const CHAT_WITH_ASSISTANT = BASE_URL + 'talk_with_assistant/';
const GET_LAST_CHAT = BASE_URL + '/users/user_id/last_chat';
const GET_ALL_MESSAGES = BASE_URL + 'chats/messages/';
const RENAME_AUTO_CHAT = BASE_URL + 'chats/generateName/';
const GET_LAW_HTML = BASE_URL + 'search-law';
const STREAM_RESPONSE = BASE_URL + 'stream-response';
const GET_LAST_CHAT_WITH_MESSAGES = BASE_URL + 'latest-chat/messages';


//Returns :  id: int    /   title: str
export function get_law_html(intitule) {
    const data = {
        law_title: intitule,
    };
    return api.post(GET_LAW_HTML, data).catch(error => {
        message.error(error);
    });
}


//Returns :  id: int    /   title: str
export function get_messages_of_last_chat() {
    return api.get(GET_LAST_CHAT_WITH_MESSAGES).catch(error => {
        message.error(error);
    });
}

//Returns :  id: int    /   title: str
export function create_chat(intitule) {
    const data = {
        user_id: 1,
        title: intitule,
    };
    return api.post(CREATE_CHAT_PATH, data).catch(error => {
        message.error(error);
    });
}

//Returns :  id: int    /   title: str
export function renommer_chat(intitule, chat_id) {
    const data = {
        chat_id: chat_id,
        title: intitule,
    };
    return api.put(RENOMMER_CHAT_PATH + chat_id, data).catch(error => {
        message.error(error);
    });
}

//Returns {
//   "message": "Chat deleted successfully",
//   "chat_id": 2
// }
export function delete_chat(chat_id) {
    return api.delete(DELETE_CHAT_PATH + chat_id).catch(error => {
        message.error(error);
    });
}


//Returns :{
//   "chats": [
//     {
//       "chat_id": 1,
//       "title": "xD"
//     },
//     {
//       "chat_id": 4,
//       "title": "Titanic3"
//     }
//   ]
// }
export function get_all_chats() {
    return api.get(GET_ALL_CHATS).catch(error => {
        message.error(error);
    });

}

//retrurns : [
//   {
//     "id": 17,
//     "chat_id": 1,
//     "role": "user",
//     "nbtoken": 0.00037,
//     "content": "Quel serait la durée maximm d'une période dessaie pour un CDD?",
//     "log_user": {
//       "type": "Question directe",
//       "domaine": "Droit du travail"
//     },
//     "log_llm": null
//   },
//   {
//     "id": 18,
//     "chat_id": 1,
//     "role": "assistant",
//     "nbtoken": 0.00147,
//     "content": "La durée maximale autorisée pour une période d'essai dans le cadre d'un Contrat à Durée Déterminée (CDD) est de trois mois selon l'article 2091 du Code civil du Québec.",
//     "log_user": null,
//     "log_llm": {
//       "sources": "Code civil du Québec - Article 2091",
//       "rag": "[Document(page_content='de 180 jours de la date à laquelle elle a cessé d’être visée par ce régime.\\n                    2001, c. 31, a. 397; 2022, c. 222022, c. 22, a.\\u2009284 et 28513.\\n\\nchapitre: r-12.1 - article : 398\\n\\nLes jours et parties de jour faisant partie d’une période durant laquelle une personne employée visée par le présent régime a été exonérée, immédiatement avant le 1er janvier 2001, de toute cotisation en vertu de l’article 21 de

export function get_all_messages(chat_id) {
    return api.get(GET_ALL_MESSAGES + chat_id).catch(error => {
        message.error(error);
    });
}


//example returns :
//{
//   "request": {
//     "id": 25,
//     "chat_id": 1,
//     "role": "user",
//     "nbtoken": 0.00033,
//     "content": "Quels sont les prérequis pour travailler comme éducatrice à la petite enfance ?",
//     "log_user": {
//       "type": "Question directe",
//       "domaine": "Droit du travail"
//     },
//     "log_llm": null
//   },
//   "response": {
//     "id": 26,
//     "chat_id": 1,
//     "role": "assistant",
//     "nbtoken": 0.00639,
//     "content": "Pour D de qle réussie et la durée d'apprentissage complétée depuis la délivrance de la carte.",
//     "log_user": null,
//     "log_llm": {
//       "sources": "F-5, R. 1- Règlement sur les certificats de qualification et sur l’apprentissage en matière d’électricité, de t",
//       "rag": "[Document(page_content='E-9.1) dont la fonction est d’enseigner à des élèves dans le
export function chat_with_assistant(user_message) {
    const data = {
        message: user_message,
        history: [],
    };
    console.log(" i send to backiend ", data)

    return api.post(CHAT_WITH_ASSISTANT, data).catch(error => {
        message.error(error);
    });
}


export function get_last_chat(user_id) {
    return api.get(GET_LAST_CHAT.replace(user_id,)).catch(error => {
        message.error(error);
    });
}



//
//
//
// export function stream_response(user_message, setResponseText, chat_id) {
//     const data = {
//         message: {
//             chat_id: chat_id,
//             role: 'user',
//             content: user_message,
//         },
//         history: [] // Fill in history if needed
//     };
//
//     const token = sessionStorage.getItem('token'); // Get the token from sessionStorage
//
//     if (!token) {
//         message.error('No token found in session storage');
//         return Promise.reject('No token found in session storage');
//     }
//
//     return fetch(STREAM_RESPONSE, {  // Return the fetch promise
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`,
//         },
//         body: JSON.stringify(data)
//     }).then(async (response) => {
//         if (!response.ok) {
//             const errorText = await response.text();
//             throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
//         }
//
//         const reader = response.body.getReader();
//         const decoder = new TextDecoder();
//         let done = false;
//         let buffer = ''; // Buffer for incoming data
//         let textBuffer = ''; // Buffer for parsed text
//
//         while (!done) {
//             const { value, done: readerDone } = await reader.read();
//             done = readerDone;
//             if (value) {
//                 buffer += decoder.decode(value, { stream: true });
//
//                 // Process each complete data event
//                 let parts = buffer.split('\n\n');
//                 buffer = parts.pop(); // Keep the last partial part in the buffer
//
//                 for (let part of parts) {
//                     if (part.startsWith('data: ')) {
//                         try {
//                             const json = JSON.parse(part.slice(6)); // Remove 'data: ' prefix
//                             if (json.text) {
//                                 textBuffer += json.text;
//                                 setResponseText(prev => prev + json.text); // Append text to the response
//                             }
//                         } catch (e) {
//                             console.error('Error parsing JSON:', e);
//                         }
//                     }
//                 }
//             }
//         }
//
//         // Optionally, handle the remaining buffer if needed
//         if (buffer && buffer.startsWith('data: ')) {
//             try {
//                 const json = JSON.parse(buffer.slice(6)); // Remove 'data: ' prefix
//                 if (json.text) {
//                     setResponseText(prev => prev + json.text); // Append text to the response
//                 }
//             } catch (e) {
//                 console.error('Error parsing JSON:', e);
//             }
//         }
//
//     }).catch(error => {
//         if (error.message.includes('401')) {
//             message.error('Unauthorized: Invalid or expired token');
//         } else {
//             message.error(`Error: ${error.message}`);
//         }
//         throw error; // Re-throw the error to propagate it
//     });
// }

export function stream_response(user_message, setResponseText, chat_id) {
    const data = {
        message: {
            chat_id: chat_id,
            role: 'user',
            content: user_message,
        },
        history: [] // Fill in history if needed
    };

    const token = sessionStorage.getItem('token');

    if (!token) {
        message.error('No token found in session storage');
        return Promise.reject('No token found in session storage');
    }

    return fetch(STREAM_RESPONSE, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data)
    }).then(async (response) => {
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let buffer = '';
        let textBuffer = '';
        let finalMessage = null; // Variable to store the final message

        while (!done) {
            const { value, done: readerDone } = await reader.read();
            done = readerDone;
            if (value) {
                buffer += decoder.decode(value, { stream: true });

                let parts = buffer.split('\n\n');
                buffer = parts.pop();

                for (let part of parts) {
                    if (part.startsWith('data: ')) {
                        try {
                            const json = JSON.parse(part.slice(6)); // Remove 'data: ' prefix
                            // console.log('Received JSON:', json);

                            if (json.final_message) {
                                finalMessage = json; // Store the final message
                            } else if (json.text) {
                                textBuffer += json.text;
                                // console.log(textBuffer)
                                setResponseText(textBuffer);
                                // setResponseText(prev => prev + json.text);
                            }
                        } catch (e) {
                            console.error('Error parsing JSON:', e);
                        }
                    }
                }
            }
        }
        // After the stream ends, return the final message
        return finalMessage;

    }).catch(error => {
        if (error.message.includes('401')) {
            message.error('Unauthorized: Invalid or expired token');
        } else {
            message.error(`Error: ${error.message}`);
        }
        throw error;
    });
}