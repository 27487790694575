import {chat_with_assistant} from "../Services/chat.service";
import {get_user_details, login_user} from "../Services/user.service";
import {message} from "antd";

export const SET_AUTH = 'SET_AUTH';
export const SET_LOADING = 'SET_LOADING';

export const setAuth = (isAuthenticated) => ({
    type: SET_AUTH,
    payload: isAuthenticated,
});

export const setLoading = (isLoading) => ({
    type: SET_LOADING,
    payload: isLoading,
});


export function RequestGetUserDetails(id_user) {
    return function (dispatch) {
        return get_user_details(id_user)
            .then(res => {
                if (res !== undefined) {
                    dispatch(setAuth(true));
                } else {
                    dispatch(setAuth(false));
                }
                dispatch(setLoading(false));
            })
            .catch(() => {
                message.error("Un problème est survenu, impossible de se connecter à l'application.")

            });
    };
}

export function RequestLogin(username, password,redirect) {
    return function (dispatch) {
        return login_user(username, password).then(res => {
                if(res){
                    sessionStorage.setItem("token", res.data.access_token);
                    sessionStorage.setItem("user_id", res.data.id_user);
                    sessionStorage.setItem("nom_complet", res.data.nom_complet);
                    redirect('/recherche-juridique')
                }else{
                    message.error(res.data.error)
                }
            })
            .catch(() => {
                message.error("Un problème est survenu, impossible de se connecter à l'application.")
            });
    };
}