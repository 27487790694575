import './feedback.css'
import React, {useState} from 'react';
import {Modal, Tag, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {request_create_feedback} from "../../Actions/feedback.action";

const {TextArea} = Input;

function Feedback(props) {
    const avis = [
        {variable: "sources_non_fiables", title: "Sources non fiables"},
        {variable: "pas_suivi_instructions", title: "N'a pas suivi les instructions"},
        {variable: "obsolete", title: "Contenu obsolète"},
        {variable: "trop_long", title: "Trop long"},
        {variable: "ne_veut_pas_repondre", title: "Ne veut pas répondre"},
        {variable: "autres", title: "Autres"}
    ];

    const [selectedTag, setSelectedTag] = useState(null);
    const [value, setValue] = useState(null);
    const [error, setError] = useState('');
    const history_messages = useSelector((state) => state.chat.history_messages);
    const dispatch = useDispatch();

    const onOkModal = () => {
        if (!selectedTag) {
            setError('Please select at least one tag.');
            return;
        }
        const history_cp = String([...history_messages].slice(-5));
        dispatch(request_create_feedback(selectedTag, value, history_cp, onCancelModal));
    };

    const onCancelModal = () => {
        props.onClose();
    };

    const handleTagSelect = (tag) => {
        setSelectedTag(tag);
        setError(''); // Clear error when a tag is selected
    };

    return (
        <Modal
            visible={props.visible}
            title="Feedback"
            okText="Envoyer"
            cancelText="Retour"
            onOk={onOkModal}
            onCancel={onCancelModal}
        >
            <div className="modal-content-container">
                <div className="tags-container" role="group" aria-labelledby="feedback-tags-label">
                    <div id="feedback-tags-label" className="sr-only">Select a tag for your feedback</div>
                    {avis.map((tag) => (
                        <Tag
                            key={tag.variable}
                            titleFontSize={15}
                            className={`m-1 tagAvis ${selectedTag === tag.title ? ' isSelectetag' : ''}`}
                            onClick={() => handleTagSelect(tag.title)}
                            tabIndex="0"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    handleTagSelect(tag.title);
                                }
                            }}
                            role="button"
                            aria-pressed={selectedTag === tag.variable}
                        >
                            {tag.title}
                        </Tag>
                    ))}
                </div>
                {error && <div role="alert" style={{color: 'red'}}>{error}</div>}
                <br/>
                <div className="remarques-container">
                    <TextArea
                        onChange={(e) => setValue(e.target.value)}
                        rows={4}
                        placeholder="Ecrivez nous ce qui ne va pas ici..."
                        maxLength={200}
                        value={value}
                        aria-label="Additional feedback"
                    />
                </div>
            </div>
        </Modal>
    );
}

export default Feedback;
