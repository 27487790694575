import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';
import { setAuth, setLoading } from './core/Actions/user.action';
import { BASE_URL } from './config';

const PrivateRoute = ({ element: Component, ...rest }) => {
    const dispatch = useDispatch();
    const { isAuthenticated, loading } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const token = sessionStorage.getItem("token");
            if (token) {
                dispatch(setLoading(true));
                try {
                    const response = await axios.get(`${BASE_URL}check-auth`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    if (response.data.success && response.data.data.is_authenticated) {
                        dispatch(setAuth(true));
                    } else {
                        dispatch(setAuth(false));
                        navigate("/");
                    }
                } catch (error) {
                    dispatch(setAuth(false));
                    navigate("/");
                } finally {
                    dispatch(setLoading(false));
                    setInitialized(true);
                }
            } else {
                navigate("/");
                setInitialized(true);
            }
        };
        checkAuth();
    }, [dispatch, navigate]);

    if (loading || !initialized) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        message.error('You need to be logged in to access this page.');
        return <Navigate to="/" />;
    }

    return <Component {...rest} />;
};

export default PrivateRoute;
