import {
    chat_with_assistant,
    create_chat,
    delete_chat,
    get_all_chats,
    get_all_messages, get_law_html, get_messages_of_last_chat,
    renommer_chat, stream_response,
} from '../Services/chat.service';
import {message} from "antd";

export const SET_LOADING_VALUE = 'SET_LOADING_VALUE';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_CHATS = 'SET_CHATS';
export const ADD_CHAT = 'ADD_CHAT';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const UPDATE_BY_ID = 'UPDATE_BY_ID';
export const DELETE_CHAT = 'DELETE_CHAT';
export const RENAME_CHAT = 'RENAME_CHAT';
export const SET_CHAT_ID = 'SET_CHAT_ID';
export const CREATE_NEW_EMPTY_CHAT = 'CREATE_NEW_EMPTY_CHAT';
export const SET_INSTRUCTION = 'SET_INSTRUCTION';
export const SET_LAW_HTML = 'SET_LAW_HTML';
export const SET_LOADING_CONTENT = 'SET_LOADING_CONTENT';
export const CREATE_STREAM_ASSISTANT_MESSAGE = 'CREATE_STREAM_ASSISTANT_MESSAGE';
export const ADD_UI_MESSAGE = 'ADD_UI_MESSAGE';
export const STREAM_MESSAGE = 'STREAM_MESSAGE';


export function request_stream_response(str_msg_user, chat_id) {
    return function (dispatch) {
        dispatch(set_loading(true));
        const setAssistantValueWithDispatch = (value) => {
            set_assistant_value(value)(dispatch);
        };
        return stream_response(str_msg_user, setAssistantValueWithDispatch, chat_id)
            .then((res) => {
                dispatch(set_loading(false));
                const chat_title = res.session_name;
                const user_message = res.user_message;
                const llm_message = res.llm_response;

                dispatch(update_message_by_id(-2, user_message));
                dispatch(update_message_by_id(-1, llm_message));

                if(chat_id===-1){
                    dispatch(add_chat({id:user_message.chat_id,title:chat_title}));
                    dispatch(set_chat_id(user_message.chat_id))
                }
            })
            .catch(error => {
                dispatch(set_loading(false));
                console.error("Error in stream response:", error);
            });
    }
}

function getMostRecentMessages(data) {
    if (data !== undefined) {
        // Trier les messages par timestamp décroissant
        const sortedMessages = data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

        // Trouver le message le plus récent pour "user" et "assistant"
        const recentUserMessage = sortedMessages.find(msg => msg.role === "user");
        const recentAssistantMessage = sortedMessages.find(msg => msg.role === "assistant");

        return {
            recentUserMessage,
            recentAssistantMessage
        };
    }
}

export function request_get_messages_of_last_chat() {
    return function (dispatch) {
        return get_messages_of_last_chat().then((res) => {
            if (res !== undefined) {
                // Call getMostRecentMessages to get the most recent user and assistant messages
                const {recentUserMessage, recentAssistantMessage} = getMostRecentMessages(res?.data?.data);
                // Dispatch actions with the obtained messages
                dispatch(set_messages(res?.data?.data));
                dispatch(set_chat_id(res?.data?.data[0]?.chat_id));
            }
        });
    }
}


// export function request_stream_response(str_msg_user, set_assistant_value, chat_id) {
//     return function (dispatch) {
//         dispatch(set_loading(true));
//
//         // Handle the stream_response with .then
//         stream_response(str_msg_user, set_assistant_value, chat_id)
//             .then((res) => {
//                 dispatch(set_loading(false));
//
//                 if (chat_id === -1) {
//                     // If chat_id is -1, a new chat was created; fetch the last chat's messages
//                     get_messages_of_last_chat()
//                         .then(result => {
//                             console.log("from -1 get messages .data.data : ", result.data.data)
//                             dispatch(set_messages(result.data.data));
//                             dispatch(set_chat_id(result.data.data[0]?.chat_id));
//                         })
//                         .catch(error => {
//                             console.error('Failed to fetch messages of the last chat: ', error);
//                         });
//                 } else {
//                     dispatch(get_all_messages(chat_id))
//                         .then(res => {
//                             if (res !== undefined) {
//                                 console.log("from id_chat get messages .data.data : ", res.data.data)
//                                 dispatch(set_messages(res.data.data));
//                                 console.log("before it cleaned");
//                             }
//                         })
//                         .catch(error => {
//                             console.error('Failed to fetch all messages: ', error);
//                         });
//                 }
//                 set_assistant_value('');
//             })
//             .catch(error => {
//                 dispatch(set_loading(false)); // Ensure loading state is turned off on error
//                 set_assistant_value('');
//                 console.error('Une erreur est survenue ', error);
//             });
//     };
// }


export function request_chat_with_assistant(user_message) {
    return async (dispatch) => {
        dispatch(set_loading(true));
        try {
            const res = await chat_with_assistant(user_message);
            const updatedHistory = res?.data?.data?.updated_history;

            if (updatedHistory) {
                dispatch(set_messages(updatedHistory));

                if (user_message.chat_id === -1) {
                    const userId = sessionStorage.getItem('user_id');
                    dispatch(request_get_chats(userId));
                    const newChatId = updatedHistory[0]?.chat_id;
                    if (newChatId) {
                        dispatch(set_chat_id(newChatId));
                    }
                }
            }
        } catch (error) {
            console.error("Erreur lors du chat avec l'assistant : ", error);
            message.error("Erreur lors du chat avec l'assistant.");
        } finally {
            dispatch(set_loading(false));
        }
    };
}


export function request_get_law_html(intitule) {
    return function (dispatch) {
        dispatch(set_loading_content(true));
        return get_law_html(intitule).then(res => {
            if (res !== undefined) {
                dispatch(set_law_html(res.data.data.content));
            }
            dispatch(set_loading_content(false));
        }).catch(error => {
            console.error("Erreur lors de récupération des lois ", error);
            message.error("Erreur lors de la récupération des lois du chat.");
        });
    };
}


export function request_rename_chat(intitule, chat_id) {
    return function (dispatch) {
        return renommer_chat(intitule, chat_id).then(res => {
            if (res !== undefined) {
                dispatch(rename_chat(res.data.data.id, res.data.data.title));
                message.success("Le nom de votre chat a été changé avec succès.");
            }
        }).catch(error => {
            console.error("Erreur lors du renommage du chat : ", error);
            message.error("Erreur lors du renommage du chat.");
        });
    };
}

export function request_get_chats() {
    return function (dispatch) {
        return get_all_chats().then(res => {
            if (res !== undefined) {
                dispatch(set_chats(res.data.data));
            }
        }).catch(error => {
            console.error("Erreur lors de la récupération des chats : ", error);
            message.error("Erreur lors de la récupération des chats.");
        });
    };
}


export function request_delete_chat(chat_id) {
    return function (dispatch) {
        return delete_chat(chat_id).then(res => {
            if (res !== undefined) {
                message.success('Chat supprimé avec succès !');
                dispatch(supprimer_chat(chat_id));
                dispatch(set_chat_id(-1));
                dispatch(set_messages([]));
            }
        }).catch(error => {
            console.error("Erreur lors de la suppression du chat : ", error);
            message.error("Erreur lors de la suppression du chat.");
        });
    };
}

export function request_create_chat(intitule) {
    return function (dispatch) {
        return create_chat(intitule).then(res => {
            if (res !== undefined) {
                dispatch(add_chat(res.data));
                dispatch(set_chat_id(res.data.id));
                dispatch(set_messages([]));
            }
        }).catch(error => {
            console.error("Erreur lors de la création du chat : ", error);
            message.error("Erreur lors de la création du chat.");
        });
    };
}

export function request_get_messages(chat_id) {
    return function (dispatch) {
        return get_all_messages(chat_id)
            .then(res => {
                if (res) {
                    const messages = res.data.data;
                    dispatch(set_chat_id(chat_id));
                    dispatch(set_messages(messages));
                }
            })
            .catch(error => {
                // Log the error for debugging
                console.error("Erreur lors de la récupération des messages : ", error);
                // Display error message
                message.error("Erreur lors de la récupération des messages.");
            });
    };
}

export const set_chat_id = (chat_id) => {
    return {
        type: SET_CHAT_ID,
        chat_id: chat_id,
    };
};

export const set_loading = (loading) => {
    return {
        type: SET_LOADING_VALUE,
        value: loading,
    };
};

export const set_messages = (messages) => ({
    type: SET_MESSAGES,
    value: messages, // Make sure this is a new array and not a reference to the existing one
});

export const set_chats = (chats) => {
    return {
        type: SET_CHATS,
        value: chats,
    };
};

//add message from backend complete
export const add_message = (message) => {
    return {
        type: ADD_MESSAGE,
        value: message,
    };
};

//fucntion to add a msg without chat_id, managed with ui only
export const add_ui_message = (str_message,role, chat_id) => {
    let message = {
        id:role==='user' ? -2:-1,
        chat_id: chat_id,
        role: role,
        content: str_message,
        temporary: true
    };
    return {
        type: ADD_UI_MESSAGE,
        value: message,
    };
};

export const add_chat = (newChat) => {
    return {
        type: ADD_CHAT,
        value: newChat,
    };
};

export const supprimer_chat = (id) => {
    return {
        type: DELETE_CHAT,
        value: id,
    };
};

export const rename_chat = (chat_id, title) => {
    return {
        type: RENAME_CHAT,
        chat_id: chat_id,
        title: title,
    };
};

export const update_message_by_id = (id, message) => {
    return {
        type: UPDATE_BY_ID,
        id: id,
        message: message,
    };
};

export const create_new_empty_chat = () => {
    message.success('Un nouveau chat a été créé avec succès.');
    return {
        type: CREATE_NEW_EMPTY_CHAT,
    };
};

export const set_instruction = (instruction) => {
    return {
        type: SET_INSTRUCTION,
        instruction: instruction,
    };
};

export const set_law_html = (content) => {
    return {
        type: SET_LAW_HTML,
        content: content,
    };
};


export const set_loading_content = (value) => {
    return {
        type: SET_LOADING_CONTENT,
        value: value,
    };
};

export const add_empty_assistant_message = (value) => {
    return {
        type: CREATE_STREAM_ASSISTANT_MESSAGE,
        value: value,
    };
};


// export const set_assistant_value = (value) => {
//     return {
//         type: SET_ASSISTANT_VALUE,
//         value: value,
//     };
// };

export function set_assistant_value(value) {
    // console.log(value);
    return function (dispatch) {
        dispatch({
            type: STREAM_MESSAGE,
            value: value
        });
    };
}
